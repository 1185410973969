import React from "react";
import { Header, Footer } from "../../shared";
import { MainContext } from "../../../App";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Testimonials from "../landing/testimonials/Testimonials";
import axios from "axios";
import SimpleSlider from "../landing/testimonials/Slider";


const SuccessStories = () => {
  const [forgotModal, setForgotModal] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const [showSupportModal, setShowSupportModal] = React.useState(false);
  const [successRate, setSuccessRate] = React.useState(67);
  const [companyList, setCompanyList] = React.useState([]);
  const [universityList, setUniversityList] = React.useState([]);
  const [screenshotList, setScreenshotList] = React.useState([]);
  const context = React.useContext(MainContext);
  const is_2fa = localStorage.getItem("is_2fa") === "true" ? true : false;

  const navigate = useNavigate();
  const currentURL = window.location.pathname;

  const loadSuccessRate = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_QA_API}/success-story`
    );
    setSuccessRate(data.success_rate);
    setCompanyList(data.company_list);
    setUniversityList(data.university_list);
    setScreenshotList(data.screenshot_list);
  }

  useEffect(() => {
    loadSuccessRate();
  }, [])

  useEffect(() => {
    if (is_2fa && !context.is2faVerified && context.login.isLoggedIn) {
      if (context.isNewDevice === false) {
        context.setIs2faVerified(true);
        localStorage.setItem("is_2fa_verified", true);
        // navigate(context.isVantage === true ? "/linux" : "/statistics");
        navigate(context.isVantage === true ? "/linux" : ( context.isCertificate ? "/stats" : "/statistics"))
      } else {
        if (context.isLoginLimited === true) {
          context.setLoginLimitedModal(true);
          localStorage.clear();
          context.login.setIsLoggedIn(false);
        } else {
          context.setVerifyOTPModal(true);
        }
      }
    }
  }, [currentURL, context.login.isLoggedIn]);

  return (
    <>
      <div className="">
        <Header
          className="relative"
          login={context.login}
          loginModal={context.loginModal}
          setLoginModal={context.setLoginModal}
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          signUp={signUp}
          setSignUp={setSignUp}
          verifyOTPModal={context.verifyOTPModal}
          setVerifyOTPModal={context.setVerifyOTPModal}
          LoginLimitedModal={context.isLoginLimited}
          setLoginLimitedModal={context.setLoginLimitedModal}
          showTermsModal={showTermsModal}
          setShowTermsModal={setShowTermsModal}
          showSupportModal={showSupportModal}
          setShowSupportModal={setShowSupportModal}
        />
      </div>
      <div className="flex flex-col article-section">
        <div className="py-12 text-gray-g1 flex flex-col gap-1 px-8"></div>
        <div className="text-2xl font-bold px-8">
          <a href="/success_stories">Success Stories</a>
        </div>
        <div className="text-gray-g1 flex flex-col gap-1 mt-8">
          <SimpleSlider images={universityList} successRate={successRate} />

          <div className="my-8 text-center px-8">
            <p>
              Our candidates have secured the most coveted roles of quant researcher, trader, and portfolio manager in:
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-8">
            {companyList.map((company, index) => (
              <div
                key={index}
                className="bg-green-100 p-2 rounded-md shadow-md text-center"
              >
                <p className="text-lg font-bold text-green-g2">{company.name}</p>
                <div className="p-4 cursor-pointer flex items-center justify-center rounded-lg h-28 w-auto mx-auto">
                  <img src={company.url} className=" max-w-full max-h-full object-contain" />
                </div>
              </div>
            ))}
          </div>

          <div className="bg-green-g2 py-12 mt-8 ">
            <div className="px-8 mx-auto flex flex-wrap justify-between">
              {screenshotList.map((image, index) => (
                <div key={index} className="w-full md:w-1/3 p-2">
                  <div className="bg-white rounded-lg shadow-lg shadow-green-200/50 overflow-hidden">
                    <img src={image} alt={`Image ${index + 1}`} className="w-full h-auto" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Testimonials />
        </div>
        
      </div>
      <Footer
        setShowTermsModal={setShowTermsModal}
        setShowSupportModal={setShowSupportModal}
      />
    </>
  );
};

export default SuccessStories;
