import React from "react";
import { Header, Footer } from "../../shared";
import { MainContext } from "../../../App";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ProPricingImg from "./images/pro_pricing.png";
import StuPricingImg from "./images/stu_pricing.png";

const AtAGlance = () => {
  const [forgotModal, setForgotModal] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const [showSupportModal, setShowSupportModal] = React.useState(false);
  const [canvaUri, setCanvaUri] = React.useState(null);
  const context = React.useContext(MainContext);
  const is_2fa = localStorage.getItem("is_2fa") === "true" ? true : false;

  const navigate = useNavigate();
  const currentURL = window.location.pathname;

  useEffect(() => {
    if (is_2fa && !context.is2faVerified && context.login.isLoggedIn) {
      if (context.isNewDevice === false) {
        context.setIs2faVerified(true);
        localStorage.setItem("is_2fa_verified", true);
        // navigate(context.isVantage === true ? "/linux" : "/statistics");
        navigate(context.isVantage === true ? "/linux" : ( context.isCertificate ? "/stats" : "/statistics"))
      } else {
        if (context.isLoginLimited === true) {
          context.setLoginLimitedModal(true);
          localStorage.clear();
          context.login.setIsLoggedIn(false);
        } else {
          context.setVerifyOTPModal(true);
        }
      }
    }
  }, [currentURL, context.login.isLoggedIn]);

  const loadAtAGlance = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_QA_API}/at-a-glance`
    );
    setCanvaUri(data.canva_uri);
  };

  useEffect(() => {
    loadAtAGlance();
  }, []);

  return (
    <>
      <div className="">
        <Header
          className="relative"
          login={context.login}
          loginModal={context.loginModal}
          setLoginModal={context.setLoginModal}
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          signUp={signUp}
          setSignUp={setSignUp}
          verifyOTPModal={context.verifyOTPModal}
          setVerifyOTPModal={context.setVerifyOTPModal}
          LoginLimitedModal={context.isLoginLimited}
          setLoginLimitedModal={context.setLoginLimitedModal}
          showTermsModal={showTermsModal}
          setShowTermsModal={setShowTermsModal}
          showSupportModal={showSupportModal}
          setShowSupportModal={setShowSupportModal}
        />
      </div>
      <div className="flex flex-col article-section px-8">
        <div className="py-12 text-gray-g1 flex flex-col gap-1"></div>
        <div className="text-2xl font-bold">
          <a href="/at_a_glance">Interview</a>
        </div>
        <div className="text-gray-g1 flex flex-col gap-1 w-full md:w-[80%] m-auto">
          <h1 className="text-center text-4xl font-bold mb-4 text-green-g2">
            QFP Interview
          </h1>
          <p className="mb-2">
            QFP’s <b>Interview platform</b> is a <b>specialized career development resource</b> for <b>students and professionals</b> designed to increase successful outcomes in Quant interviews.
          </p>
          <h2 className="text-xl font-bold my-2 text-green-g2">Why?</h2>
          <p className="mb-2">
            Being prepared for the interview is ultimately the key differentiator. QFP’s <b>Interview platform</b> is specifically tailored to the content and expectations of quant finance interviews, so that candidates know what to expect in their interviews and prepare well even when they are hard pressed for time.
          </p>
          <h2 className="text-xl font-bold my-2 text-green-g2">At a glance:</h2>
          <ol className="list-disc ml-8">
            <li className="mb-2">QFP currently comes as a self-guided online platform</li>
            <li className="mb-2">As part of the subscription, users get <b>access to our 4 major modules (stats, finance, coding, & machine learning),</b> as well as the <b>ability to interact</b> with fellow students of different schools and industry professionals anonymously through the <b>in-built discussion</b>forum.</li>
              <ol className="list-disc ml-8">
                <li className="mb-2">Each module includes the <b>main topics</b> candidates are likely to encounter in their interviews, as well as <b>popular questions</b> (updated regularly) so they can practice as they prepare.</li>
              </ol>
            <li className="mb-2">There are a number of <b>additional features</b> also available on the platform, including <b>in-house Python coding</b> (so they don’t have to leave the platform to run code), & the ability to <b>filter topics and questions by company.</b></li>
          </ol>
          <h2 className="text-xl font-bold my-2 text-green-g2">Key Highlights:</h2>
          <ol className="list-disc ml-8">
            <li className="mb-2">Platform content is updated frequently to reflect current & changing trends.</li>
            <li className="mb-2">More than just practice questions and answers: it is a platform where candidates can practice coding + ask questions (and get them answered within 24 hrs)</li>
            <li className="mb-2">Candidates gain an additional level of exposure to recruiters through our existing partnerships.</li>
          </ol>
          {canvaUri && (
            <div className="relative w-full h-0 pb-[56.2225%] shadow-md mt-8 mb-8 overflow-hidden rounded-md">
              <iframe
                className="absolute w-full h-full top-0 left-0 border-none p-0 m-0"
                src={canvaUri}
                allowFullScreen
                allow="fullscreen"
                loading="lazy"
                title="At a Glance"
              ></iframe>
            </div>
          )}
            <div className="flex justify-center items-center gap-4">
              <div className="w-1/3 flex flex-col items-center text-center">
                <div className="my-2">
                  <img src={ProPricingImg} className="w-full h-auto" alt="Pro Pricing" />
                </div>
              </div>
              <div className="w-1/3 flex flex-col items-center text-center">
                <div className="my-2">
                  <img src={StuPricingImg} className="w-full h-auto" alt="Student Pricing" />
                </div>
              </div>
            </div>
        </div>
      </div>
      <Footer
        setShowTermsModal={setShowTermsModal}
        setShowSupportModal={setShowSupportModal}
      />
    </>
  );
};

export default AtAGlance;
