import React from "react";

import { CertificateTopBar, CertificateLeftSideBar } from "../certificate";
import 'react-toastify/dist/ReactToastify.css'
import { MainContext } from "../../App";


const Dashboard = ({children}) => {
  const [open, setOpen] = React.useState(false)
  const context =React.useContext(MainContext)

  return (
    <>
      <div className="md:flex flex-col">
        <CertificateTopBar open={open} setOpen={setOpen} />
        <div className="flex flex-row table-container">
          <CertificateLeftSideBar themeData={context.certificateThemeData} setActiveUri={context.setActiveUri} setCount={context.setCertificateCount} setCertificateId={context.setCertificateId} certificateId={context.certificateId} />
          {!open && <>{children}</>}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
