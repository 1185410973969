import React from "react";
import { Header, Footer } from "../../shared";
import { MainContext } from "../../../App";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Certificate = () => {
  const [forgotModal, setForgotModal] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const [showSupportModal, setShowSupportModal] = React.useState(false);
  const [canvaUri, setCanvaUri] = React.useState(null);
  const context = React.useContext(MainContext);
  const is_2fa = localStorage.getItem("is_2fa") === "true" ? true : false;

  const navigate = useNavigate();
  const currentURL = window.location.pathname;

  useEffect(() => {
    
  }, [currentURL, context.login.isLoggedIn]);

  const loadCanvaUri = async () => {

  };

  useEffect(() => {
    
  }, []);

  return (
    <>
      <div className="">
        <Header
          className="relative"
          login={context.login}
          loginModal={context.loginModal}
          setLoginModal={context.setLoginModal}
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          signUp={signUp}
          setSignUp={setSignUp}
          verifyOTPModal={context.verifyOTPModal}
          setVerifyOTPModal={context.setVerifyOTPModal}
          LoginLimitedModal={context.isLoginLimited}
          setLoginLimitedModal={context.setLoginLimitedModal}
          showTermsModal={showTermsModal}
          setShowTermsModal={setShowTermsModal}
          showSupportModal={showSupportModal}
          setShowSupportModal={setShowSupportModal}
        />
      </div>
      <div className="flex flex-col article-section px-8 pb-8">
        <div className="py-12 text-gray-g1 flex flex-col gap-1"></div>
        <div className="text-2xl font-bold">
          <a href="/certificate">Certificate</a>
        </div>
        <div className="text-gray-g1 flex flex-col gap-1 w-full md:w-[80%] m-auto">
          <h1 className="text-center text-4xl font-bold mb-4 text-green-g2">
           QFP Certificate
          </h1>
          <p className="mb-2">
            Quant Finance Prep certificate is designed for the professionals looking to advance their career through continuing education. Leveraging the QFP platform, this certificate provides the deep understanding of topics and imparts skills necessary to accelerate your growth professionally.
          </p>
          <h2 className="text-xl font-bold my-2 text-green-g2">What does QFP Certificate offer?</h2>
          <p className="mb-2">
            Certificate covers the key concepts in <strong>Statistics, Finance, Coding, Machine Learning</strong> along with sample questions that are prevalent in the market. Each of these modules comes fully equipped with <strong>theory, mathematical equations, derivations, python code</strong> and a <strong>built-in interactive python platform</strong> with each theme to solidify your understanding. You will also learn <strong>Python best practices, Linux tips, Data Science</strong> and <strong>interactive visualization techniques.</strong> QFP has collaboration with the <strong>top US universities</strong> and is trusted  and used by students of MFE programs across the US (<a href="https://www.quantfinanceprep.com/success_stories" target="_blank">link</a>)
          </p>
          <h2 className="text-xl font-bold my-2 text-green-g2">Take advantage of your company's continuing education benefits</h2>
          <p className="mb-2">
            With subscription, you get 6 months of unrestricted access to all the materials above along with discussion forums associated with each topic. Study at your own pace, then take a test.  All qualifying candidates get a certificate from QFP, showing which you can get reimbursed from your company under the continuing education benefits. 
          </p>
          <p className="text-xl font-bold my-2 text-green-g2">Benefits:</p>
          <ol className="list-disc ml-8">
            <li className="mb-2">
              <b>Enhance your skills</b> to become successful in quant finance career
            </li>
            <li className="mb-2">
              <b>Advance your career</b> and solidify your understanding by getting exposure to the database of constantly updated questions
            </li>
            <li className="mb-2">
              <b>Save hundreds of thousands</b> of dollars by avoiding tuition in a Masters’ program
            </li>
            <li className="mb-2">
              <b>Continue earning for 1-2 years</b> by keeping your job and avoiding another degree
            </li>
            <li className="mb-2">
              <b>No net out of pocket cost:</b> Get <strong>100% reimbursed</strong> by your company after passing the test
            </li>
          </ol>
          <p className="text-xl font-bold text-center my-4 text-green-g2">
            What are you waiting for? Sign up for the QFP certificate today!</p>
            <div className="text-4xl font-bold text-center mt-4">Pricing</div>
            <div className="mx-4 my-4 flex justify-center">
              <div className="w-full flex flex-col items-center text-center">
                <div className="my-6">
                  <div className="text-green-g1 text-5xl font-bold flex justify-center items-center">$5000</div>
                </div>
              </div>
            </div>
            </div>
      </div>
      <Footer
        setShowTermsModal={setShowTermsModal}
        setShowSupportModal={setShowSupportModal}
      />
    </>
  );
};

export default Certificate;
 