import React, { useState, useEffect } from "react";
import downArrow from "./images/downArrow.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import moment from 'moment'

import leftArrow from "./images/leftArrow.svg";
// import Profile from "./images/Profile.png";
import { MainContext } from "../../../App";
import back from "./images/back.svg";

export default function DiscussionList() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const interviewIdArray = search.includes('interview=') ? search.split('interview=')[1].split(',') : navigate('/404')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [discussionData, setDiscussionData] = useState([])
  const [createDiscussion, setCreateDiscussion] = useState(false)
  const [refetch, setRefetch] = useState(true)
  const [discussion, setDiscussion] = useState(false);
  const context =React.useContext(MainContext)

  const isAdmin = localStorage.getItem("is_admin") === 'true' ? true : false;
  const activeUserEmail = localStorage.getItem('email')

  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token} `,
  };

  const apiCall = async () => {
    const token = localStorage.getItem('token')
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token} `
    }
    setRefetch(false)
    try {
      setLoading(true)
      if (token) {
        const { data } = await axios.get(`${process.env.REACT_APP_QA_API}/interview_`, { headers })
        setLoading(false)
        setDiscussionData(data)
      } else {
        navigate('/')
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
      if (error.response.status === 401) {
        context.setIsLoggedIn(false)
        localStorage.clear()
        navigate('/')
      }
      setError('Some error occured. Please reload your page.')
    }
  }
  useEffect(() => {
    if (refetch) {
      apiCall()
    }
  }, [refetch])

  const handleDiscussionDelete = async (e, id) => {
    e.preventDefault();
    try {
      await axios.delete(
        `${process.env.REACT_APP_QA_API}/discussion/${id}`,
        { headers }
      );
      apiCall();
    } catch (error) {
      console.error(error);
    }
    return
  }

  let arr = []
  for(let i=0; i<discussionData.length; i++){
    for(let j=0; j<interviewIdArray.length; j++){
      if(+interviewIdArray[j] === +discussionData[i].interview.id){
        arr = [...arr, discussionData[i]]
      }
    }
  }
  
  let count = 0;
  arr.forEach(item => {
    count += item.discussions.length
    return;
  })
  return <>
    <div className="w-full overflow-hidden table-container overflow-y-scroll flex flex-col gap-6 py-4 text-white bg-gray-g1 p-8">
      <div className=" w-full flex items-center justify-between">
        <div>
          <span className="font-bold">Discussion</span>
          <span className="ml-2 px-2 py-1 font-bold text-white bg-green-g1 rounded-full">
            {count}
          </span>
        </div>

        <div className="flex gap-2">
          <span
            className="px-4 py-2 bg-green-g1 text-white rounded-full font-bold cursor-pointer"
            onClick={() => setCreateDiscussion(!createDiscussion)}
          >
            + Create Discussion
          </span>
          <span
            className="p-2 bg-white hover:shadow-lg rounded-full font-bold cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <img src={downArrow} alt='' />
          </span>
        </div>
      </div>
      {loading && <div className="h-screen text-white">Loading...</div>}
      {error && <div>{error}</div>}
      {!loading && arr.map(({ discussions, interview }, index) => (
        <div key={`discuss-${index}`}>
          <div className="flex items-center">
            <img
              src={back}
              alt={`${interview?.id}`}
              className="w-12 cursor-pointer"
              onClick={() => navigate(`/${interview?.category.toLowerCase()}?interview=${interview?.id}`)}
            />
            <span className="font-bold text-lg">
              {interview?.title}
            </span>
          </div>
          {discussions.length > 0 && discussions.map(({ id, creator, created_at, content }, index) => 
          <Link to={`/${interview.category.toLowerCase()}/discussion/${interview.id}/${id}`} 
            key={`discussion-list-${index}`}>
              <div className="py-4 px-4 my-4 flex flex-col gap-4 rounded-lg text-white bg-gray-g8 cursor-pointer">
                <div className="flex justify-between">
                  <div className="flex gap-4 items-center">
                    {/* <img src={Profile} alt="Profile" className="w-8" /> */}
                    <div className="font-bold">{ creator.username }</div>
                    <div className="text-gray-g11">{moment(created_at).toLocaleString()}</div>
                  </div>
                  {(isAdmin || (activeUserEmail === creator.email)) && (
                    <div className="flex gap-4 items-center text-sm">
                      {<div className="text-red-r1 cursor-pointer" onClick={(e) => handleDiscussionDelete(e, id)}>
                        Delete
                      </div>}
                    </div>
                  )}
                </div>
                <div className="justify-content text-sm font-bold">
                  <div className="max-w overflow-hidden" style={{maxHeight: '2.8em'}}>
                    <div style={{ display: '-webkit-box', WebkitLineClamp:2, WebkitBoxOrient: 'vertical'}}>
                      {content}
                    </div>
                  </div>
                </div>
              </div>
          </Link>)}
        </div>
      ))}
    </div>
    {createDiscussion && (
      <CreateDiscussionModal setCreateDiscussion={setCreateDiscussion} setRefetch={setRefetch} interviewIdArray={interviewIdArray} />
    )}
  </>
}


const CreateDiscussionModal = ({ setCreateDiscussion, setRefetch, interviewIdArray }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [content, setContent] = useState('')

  const context =React.useContext(MainContext)

  const handlePostContent = async () => {
    const token = localStorage.getItem('token')
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token} `
    }
    const formData = {
      content,
      "topic": interviewIdArray[0]
    }
    try {
      setLoading(true)
      if (token) {
        await axios.post(`${process.env.REACT_APP_QA_API}/discussion/`, formData, { headers })
        setLoading(false)
        setCreateDiscussion(false)
        setRefetch(true)
      }
    } catch (error) {
      setLoading(false)
      if (error.response.status === 400) {
        setError('The field may not be blank.')
      }
      if (error.response.status === 401) {
        context.setIsLoggedIn(false)
        localStorage.clear()
        navigate('/')
      }
    }
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}

          <div className="px-2 rounded-lg shadow-lg relative flex flex-col text-white bg-gray-g8 outline-none focus:outline-none">
            {/*header*/}

            <div className="m-4 flex flex-col gap-6">
              <div className="flex justify-between">
                <span className="font-bold">Ask your question</span>
                <span
                  className="font-semibold text-white cursor-pointer"
                  onClick={() => setCreateDiscussion(false)}
                >
                  X
                </span>
              </div>
              <textarea
                placeholder="Type here.."
                rows={6}
                className="w-96 p-2 text-sm rounded ring-1 ring-gray-g6 placeholder-text-gray-g2 focus:outline-none text-white bg-gray-g8"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              ></textarea>
              {error && <div className="text-red-r1 text-sm">{error}</div>}
              <div className='flex justify-end'>
                <div className=" px-6 p y-1 rounded-full bg-green-g1 text-white cursor-pointer" onClick={handlePostContent}>
                  Send
                </div>
              </div>
            </div>

            {/* header end */}
          </div>
        </div>
      </div>
      <div className="opacity-90 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};