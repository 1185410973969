import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MainContext } from "../../../App";
import Logout from "./images/Logout.svg";
import Setting from "./images/Setting.svg";
import { FaArrowRight } from "react-icons/fa";

function Items({ currentItems, setActiveUri, setCount, setCertificateId, certificateId }) {
  const navigate = useNavigate()
  const context = React.useContext(MainContext);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState([]);

  const { search } = useLocation()
  const certificateIdInParam = search.includes('certificate=') ? parseInt(search.split('certificate=')[1]) : null;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token} `,
  };
  const updateAccessHistory = async (certificate) => {
    var certificate_ = certificate;
    if (!certificate_.access_history.includes(parseInt(userId))) {
      certificate_.access_history = [...certificate_.access_history, parseInt(userId)];
      try {
        await axios.put(
          `${process.env.REACT_APP_QA_API}/certificate_/${certificate.id}`,
          certificate_,
          { headers }
        );
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleCertificateOpen = (certificate, discussions) => {
    navigate(`/${certificate.category.toLowerCase()}`);

    updateAccessHistory(certificate);
    setActiveUri(certificate.content_uri);
    setCount(discussions.length);
    setCertificateId(certificate.id);
    setSelectedType(certificate.sub_type_name);
  };

  const handleTypeClick = (type) => {
    setSelectedType(type);
    setCertificateId('');
    let temp = [...types];
    let t = temp.find(t => t.type === type);
    let tIndex = temp.findIndex( t => t.type === type);
    t.view = !t.view;
    temp[tIndex] = t;
    setTypes(temp);
  }

  useEffect(() => {
    if (context.activeUri === '' || context.activeUri === false) {
      if (currentItems.length > 0) {
        if (certificateIdInParam !== null) {
          const itemInParam = currentItems.filter(item => item.certificate.id === certificateIdInParam)[0];
          setActiveUri(itemInParam.certificate.content_uri);
          setCount(itemInParam.discussions.length);
          setCertificateId(itemInParam.certificate.id);
        } else {
          setActiveUri(currentItems[0].certificate.content_uri);
          setCount(currentItems[0].discussions.length);
          setCertificateId(currentItems[0].certificate.id);
        }

        let temp = currentItems.map(ci => ci.certificate.sub_type_name)
        temp = Array.from(new Set(temp));
        temp = temp.map(t => {
          return {
            type: t,
            values: currentItems.filter(ci => ci.certificate.sub_type_name === t),
            view: true
          }
        })
        setTypes(temp);
      } else {
        setTypes([]);
      }
    }
  }, [currentItems])

  return (
    <div className="py-6 text-[#999ca2] flex flex-col gap-1">
      {types && types.length > 0 && types.map((type, j) => {
        return (
          <>
          {type.type && !type.type.startsWith('None') &&
            <div className={`py-2 px-2 rounded flex justify-between items-center font-bold cursor-pointer hover:shadow ${type.type === selectedType ? "text-[#ff764d]" : "text-[#999ca2]"}`} onClick={() => handleTypeClick(type.type)}>
              <div>{type.type}</div>
              <div className={`${type.view ? 'rotate-90' : 'rotate-0'} transition-all duration-300 ease-in-out`}>
                <FaArrowRight size={15}/>
              </div>
            </div>}
          {type.values &&
            type.values.map(({ certificate, discussions }, index) => {
              if (certificate.sub_type_name === type.type) {
                return (
                  <div class={`${type.view ? 'block' : 'hidden'} transition-all duration-200 ease-in-out`}>
                    <div className="py-2 px-3 rounded flex justify-between font-bold cursor-pointer hover:shadow"
                      key={index} onClick={() => handleCertificateOpen(certificate, discussions)}
                    >
                      {certificateIdInParam === null ? (
                        <span className={`font-verdana text-xl font-normal ${certificateId === certificate.id ? "text-[#ff764d]" : "text-[#999ca2]"}`} >
                          {index + 1}. {certificate.title.charAt(0).toUpperCase()}
                          {certificate.title.slice(1)}
                        </span>
                      ) : (
                        <span className={`font-verdana text-xl font-normal ${certificateIdInParam === certificate.id ? "text-[#ff764d]" : "text-[#999ca2]"}`} >
                          {index + 1}. {certificate.title.charAt(0).toUpperCase()}
                          {certificate.title.slice(1)}
                        </span>
                      )}
                    </div>
                  </div>
                )
              }
            })}
        </>)
      })}
    </div>
  );
}

const SideBar = ({ themeData, setActiveUri, setCount, setCertificateId, certificateId }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const context = React.useContext(MainContext);

  useEffect(() => {
    const certificateType = pathname.split("/")[1];
    if(certificateType === 'stats') {
      setSelectedTab(0)
      return;
    }
    if(certificateType === 'finance') {
      setSelectedTab(1)
      return;
    }
    if(certificateType === 'coding') {
      setSelectedTab(2)
      return;
    }
    if(certificateType === 'ml') {
      setSelectedTab(3)
      return;
    }
    if (certificateType === "linux") {
      setSelectedTab(4);
      return;
    }
    if (certificateType === "data_science") {
      setSelectedTab(5);
      return;
    }
    if (certificateType === "visualization") {
      setSelectedTab(6);
      return;
    }
  }, [pathname]);
  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    try {
      setLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token} `,
      };
      await axios.post(
        `${process.env.REACT_APP_QA_API}/logout`,
        {},
        { headers }
      );
      setLoading(false);
      localStorage.clear();
      context.login.setIsLoggedIn(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Sidebar Toggle Button */}
      <div
        className={`overflow-hidden table-container sticky h-screen bg-gray-g1 text-[#999ca2] 
        ${isCollapsed ? "w-7" : "w-7"} flex md:flex-col relative`}
      >
        {/* Full-height button */}
        <button
          className="border-solid bg-gray-g8 rounded-none w-full h-full flex items-center justify-center 
          text-2xl absolute inset-0"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? "»" : "«"}
        </button>
      </div>
        
      {/* Sidebar */}
      <div
        className={`overflow-y-scroll table-container sticky h-screen 
        ${isCollapsed ? "hidden md:block" : "w-full md:w-[30%] lg:w-1/6"} 
        bg-gray-g1 text-[#999ca2]`}
      >
        <div className="h-full flex justify-between flex-col border-gray-g8 border-r-2">
          <div
            className={`mx-4 my-8 flex flex-col text-lg vantage-height-1 ${
              isCollapsed && "hidden"
            }`}
          >
            <Items
              currentItems={themeData}
              setActiveUri={setActiveUri}
              setCount={setCount}
              setCertificateId={setCertificateId}
              certificateId={certificateId}
            />
            <div
              className={`mt-auto ${isCollapsed && "hidden"}`}
            >
              <div
                className={`px-5 py-3 w-full flex gap-6 rounded-full cursor-pointer ${
                  isCollapsed && "hidden"
                }`}
                onClick={handleLogout}
              >
                <img src={Logout} alt="icon" className="w-5" /> Log out
              </div>
              <Link
                to="/settings"
                className={`px-5 py-3 w-full flex gap-6 rounded-full cursor-pointer ${
                  selectedTab === 6 && ""
                }`}
              >
                <img src={Setting} alt="Setting" className="w-5" />
                Settings
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
