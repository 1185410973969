import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom"
import axios from 'axios'
import Reaptcha from 'reaptcha'

import logo from "./images/logo.svg";
import logoGreen from "./images/logo-dark.svg";
import ArrowLeft from "./images/arrow-left.svg";
import Eye from "./images/Eye.svg";
import { MainContext } from "../../../App";
import { browserName, osName } from "react-device-detect";
import { DeviceUUID } from "device-uuid"
import TermsAndConditionsModal from "../../local/dashboard/setting/TermsAndConditions";
import Menu from "./images/Menu.svg";

const TAB_LIST_ARRAY = [
  {
    id: 0,
    title: "Interview",
    href: "/at_a_glance",
  },
  {
    id: 1,
    title: "Vantage",
    href: "/vantage",
  },
  {
    id: 2,
    title: "Certificate",
    href: "/certificate",
  },
  {
    id: 3,
    title: "Insights",
    href: "/qfp_insights",
  },
  {
    id: 4,
    title: "Success Stories",
    href: "/success_stories",
  },
]

const Header = ({login, loginModal, setLoginModal, forgotModal, setForgotModal, 
  signUp, setSignUp, verifyOTPModal, setVerifyOTPModal, showTermsModal, setShowTermsModal,
  showSupportModal, setShowSupportModal}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()
  const context =React.useContext(MainContext)
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      login.setIsLoggedIn(true)
    } else {
      login.setIsLoggedIn(false)
      localStorage.clear()
    }
  }, [])

  useEffect(() => {
    const param =pathname.split('/')[1]
    if(param === 'qfp_insights') {
      setSelectedTab(0)
      return;
    }
    if(param === 'at_a_glance') {
      setSelectedTab(1)
      return;
    }
    if(param === 'vantage') {
      setSelectedTab(2)
      return;
    }
    if(param === 'certificate') {
      setSelectedTab(3)
      return;
    }
    if(param === 'success_stories') {
      setSelectedTab(4)
      return;
    }
  }, [pathname])
  
  return (
    <div className="md:h-14 bg-white fixed w-full z-50 border-b-2">
      <div className="h-full px-0 md:px-2 md:px-6 flex justify-between items-center  ">
        <div className="hidden md:flex">
          <a href="/" className="w-48 md:w-48 cursor-pointer"><img src={logoGreen} alt="logo" className="w-48" />
          </a>
          <div className="ml-4 space-x-1">
            <a href="/at_a_glance" className="md:px-3 md:py-2 hover:bg-gray-g13 rounded-full font-semibold text-green-g1 cursor-pointer">
              Interview
            </a>
            <a href="/vantage" className="md:px-3 md:py-2 hover:bg-gray-g13 rounded-full font-semibold text-green-g1 cursor-pointer">
              Vantage
            </a>
            <a href="/certificate" className="md:px-3 md:py-2 hover:bg-gray-g13 rounded-full font-semibold text-green-g1 cursor-pointer">
              Certificate
            </a>
            <a href="/qfp_insights" className="md:px-3 md:py-2 hover:bg-gray-g13 rounded-full font-semibold text-green-g1 cursor-pointer">
              Insights
            </a>
            <a href="/success_stories" className="md:px-3 md:py-2 hover:bg-gray-g13 rounded-full font-semibold text-green-g1 cursor-pointer">
              Success Stories
            </a>
          </div>
        </div>
        <div className="flex gap-4 text-sm md:text-base font-semibold hidden md:flex">
          {!login.isLoggedIn && <div
            className="md:px-5 md:py-2 bg-green-g1 rounded-full font-semibold text-white cursor-pointer"
            onClick={() => setLoginModal(true)}
          >
            Log in
          </div>}
        </div>

        <div
          className={`text-white w-full md:hidden ${open && "h-screen flex justify-between flex-col bg-gray-g1 "
            }`}
        >
          <div>
            <div className="md:hidden bg-gray-g1 flex px-4 py-4 md:mx-0 justify-between md:justify-center items-center ">
              <Link to="/">
                <img src={logo} alt="logo" className="w-48" />
              </Link>
              <img
                src={Menu}
                alt="menu"
                className={` ${open && " p-2 bg-gray-g8 rounded-lg cursor-pointer"
                  }`}
                onClick={() => setOpen(!open)}
              />
            </div>
          </div>
          <div className={`${open ? "md:hidden mx-4 flex flex-col text-lg" : "hidden"} `}>
            <TabList
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              open={open}
              setOpen={setOpen}
            />
            {!login.isLoggedIn ? (<div
              className="text-center py-2 rounded-full cursor-pointer "
              onClick={() => setLoginModal(true)}
            >
              <span className="text-green-g1">Login</span>
            </div>) : ''}
          </div>
          <div />
        </div>
      </div>
      {loginModal && (
        <Modal
          setLoginModal={setLoginModal}
          setForgotModal={setForgotModal}
          setSignUp={setSignUp}
          setIsLoggedIn={login.setIsLoggedIn}
          setVerifyOTPModal={setVerifyOTPModal}
        />
      )}
      {forgotModal && (
        <ForgotModal
          setForgotModal={setForgotModal}
          setLoginModal={setLoginModal}
        />
      )}
      {verifyOTPModal && (
        <VerifyOTPModal
          setVerifyOTPModal={setVerifyOTPModal}
        />
      )}
      {context.showLegalDisclaimerModal && (
        <LegalDisclaimerModal />
      )}
      {context.loginLimitedModal && (
        <LoginLimitedModal />
      )}
      {signUp && <SignUpModal setSignUp={setSignUp} />}
      {
        showSupportModal && (
          <SupportModal
            setShowSupportModal={setShowSupportModal}
          />
        )
      }
      {
        showTermsModal && (
          <TermsAndConditionsModal
            setShowModal={setShowTermsModal}
            acceptable={false}
          />
        )
      }
    </div>
  );
};

export default Header;

const Modal = ({ setLoginModal, setForgotModal, setSignUp, setIsLoggedIn, setVerifyOTPModal }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const context = React.useContext(MainContext);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    ua: '',
    device_id: ''
  })
  const [error, setError] = useState({
    email: '',
    password: '',
    submit: ''
  })
  const handleLogin = async () => {
    if (!formData.email) {
      setError({ ...error, email: 'Email is required' })
      return;
    }
    if (!formData.password) {
      setError({ ...error, password: 'Password is required' })
      return;
    }
    try {
      setLoading(true)
      const ua = `${osName}_${browserName}`
      const device_id = new DeviceUUID().get()

      formData.ua = ua
      formData.device_id = device_id
      const { data, status } = await axios.post(`${process.env.REACT_APP_QA_API}/login`, formData)
      setLoading(false)
      if (status === 200) {
        setLoginModal(false)
        localStorage.setItem('token', data.token)
        localStorage.setItem('email', formData.email)
        localStorage.setItem('userId', data.data.id)
        localStorage.setItem('is_legal_edu', data.data.is_legal_edu)
        localStorage.setItem('is_2fa', data.data.is_2fa)
        localStorage.setItem('is_2fa_verified', data.data.is_2fa_verified)
        context.setIs2faVerified(data.data.is_2fa_verified)
        localStorage.setItem('is_subscribed', data.data.is_subscribed)
        localStorage.setItem('is_vantage_subscribed', data.data.is_vantage_subscribed)
        localStorage.setItem('is_vantage', data.data.is_vantage)
        localStorage.setItem('is_certificate_subscribed', data.data.is_certificate_subscribed)
        localStorage.setItem('is_certificate', data.data.is_certificate)
        localStorage.setItem('subscription_type', data.data.subscription_type)
        localStorage.setItem('vantage_subscription_type', data.data.vantage_subscription_type)
        localStorage.setItem('certificate_subscription_type', data.data.certificate_subscription_type)
        localStorage.setItem('is_admin', data.data.is_admin)
        localStorage.setItem('is_new_device', data.data.is_new_device)

        context.setIsNewDevice(data.data.is_new_device)
        context.setIsAdmin(data.data.is_staff)
        context.setIsSubscription(data.data.is_subscribed)
        context.setIsVantageSubscription(data.data.is_vantage_subscribed)
        context.setIsVantage(data.data.is_vantage)

        context.setIsCertificateSubscription(data.data.is_certificate_subscribed)
        context.setIsCertificate(data.data.is_certificate)
        context.setIsLoginLimited(data.is_login_limited)
        if (data.is_login_limited) {
          context.setLoginLimitedModal(true)
          localStorage.clear()
          return
        }
        setIsLoggedIn(true)
        if (!data.data.is_2fa) {
          // navigate(data.data.is_vantage === true ? "/linux" : "/statistics")
          navigate(data.data.is_vantage === true ? "/linux" : ( data.data.is_certificate ? "/stats" : "/statistics"))
        } else {
          localStorage.setItem('is_2fa', true)
          context.setIs2faVerified(false)
          localStorage.setItem('is_2fa_verified', false)
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${data.token} `,
          };
          try {
            if (!data.is_login_limited) {
              if (data.data.is_new_device) {
                await axios.get(`${process.env.REACT_APP_QA_API}/2fa/${data.data.id}`, { headers })
              }
              // navigate(data.data.is_vantage === true ? "/linux" : "/statistics")
              navigate(data.data.is_vantage === true ? "/linux" : ( data.data.is_certificate ? "/stats" : "/statistics"))
              // window.location.href = '/'
            }
          } catch (error) {
            console.error(error)
          }
        }
      }
    } catch (error) {
      setLoading(false)
      setIsLoggedIn(false)
      localStorage.clear()
      const statusCode = error.response.status
      if (statusCode === 400) {
        setError({ ...error, submit: error.response.data.message })
      }
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setError({ ...error, [name]: '' })
    setFormData({ ...formData, [name]: value })
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if(event.key === 'Enter') {
        event.preventDefault()
        handleLogin()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [formData])

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-x-0 z-50 outline-none focus:outline-none top-0 h-full p-4">
        <div className="relative w-auto my-6 mx-auto max-w-3xl pt-8">
          {/*content*/}
          <div className="my-8 flex justify-center">
            <img src={logo} alt="logo" className="w-64" />
          </div>
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}

            <div className="md:w-96 md:mx-8 mx-4 mt-8">
              <div className="mb-8 flex justify-between font-bold text-xl">
                <span className="text-white md:hidden">x</span>
                <span>Log in</span>
                <span
                  className="cursor-pointer"
                  onClick={() => setLoginModal(false)}
                >
                  x
                </span>
              </div>

              <div className="md:my-4 my-8 flex flex-col gap-4">
                {error.submit && <div className="text-red-r1">{error.submit}</div>}
                <div className="text-sm text-semibold">Email</div>
                <input
                  className={`w-full px-4 py-2 focus:border-white
                  border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                  ${error.email ? 'border-red-r1 focus:border-red-r1' : 
                  'border-gray-g6 focus:border-green-g1'} `}
                  type="email"
                  placeholder="Enter Email"
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                />
                {error.email && <div className="text-red-r1 text-sm">{error.email}</div>}
              </div>
              <div className="md:my-4 my-8 flex flex-col gap-4">
                <div className="text-sm text-semibold">Password</div>
                <div className="flex">
                  <input
                    className={`w-full px-4 py-2 focus:border-white
                    border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                    ${error.password ? 'border-red-r1 focus:border-red-r1' : 
                    'border-gray-g6 focus:border-green-g1'} `}
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    name='password'
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <img src={Eye} alt="ShowPassword" className="w-6 -ml-10 cursor-pointer" onClick={() => setShowPassword(!showPassword)} />
                </div>
                {error.password && <div className="text-red-r1 text-sm">{error.password}</div>}
              </div>
              <div
                className="md:my-4 my-8 text-sm text-green-g1 cursor-pointer text-center md:text-left"
                onClick={() => {
                  setLoginModal(false);
                  setForgotModal(true);
                }}
              >
                Forgot Password?
              </div>
              <div className="text-center">
                <button className="mx-auto w-32 my-8 md:my-4 py-2 rounded-full text-center text-semibold bg-green-g1 text-white cursor-pointer" onClick={handleLogin}>
                  {loading ? "loading..." : "Log In"}
                </button>
              </div>
              {/* </Link> */}
            </div>
            <div className="px-6 py-4 rounded-lg bg-gray-g7 text-center text-sm">
              Don't have an account?{" "}
              <span
                className="text-green-g1 cursor-pointer"
                onClick={() => {
                  setLoginModal(false);
                  setSignUp(true);
                }}
              >
                Create new account
              </span>
            </div>

            {/* header end */}
          </div>
        </div>
      </div>
      <div className="opacity-95 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

const SignUpModal = ({ setSignUp }) => {
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    email: '',
    linkedin: '',
    firstname: '',
    lastname: '',
    username: '',
    phone: '',
    job_cities: '',
    referred_by: '',
    key_words: '',
    password: ''
  })
  const [message, setMessage] = useState('')
  const [error, setError] = useState({
    email: '',
    linkedin: '',
    username: '',
    password: '',
    signup: '',
    captcha: '',
    firstname: '',
    lastname: ''
  })
  const [verification, setVerification] = useState({
    isVerified: false,
    shouldVerify: false,
    value: null
  })
  const [showPassword, setShowPassword] = useState(false)

  const recaptchaRef = useRef(null)

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  const handleSignup = async () => {
    setError({
      signup: ''
    })
    if (!formData.firstname) {
      setError({ ...error, firstname: 'First name is required' })
      return;
    }
    if (!formData.lastname) {
      setError({ ...error, lastname: 'Last name is required' })
      return;
    }
    if (!formData.email) {
      setError({ ...error, email: 'Email is required' })
      return;
    }
    if (!formData.linkedin) {
      setError({ ...error, linkedin: 'Linkedin profile URL is required' })
      return;
    }
    if (!formData.username) {
      setError({ ...error, username: 'Username is required' })
      return;
    }
    if (!formData.password) {
      setError({ ...error, password: 'Password is required' })
      return;
    }
    if (!formData.email.match(emailRegex)) {
      setError({ ...error, email: 'Enter correct email.' })
      return;
    }
    if (formData.password.length < 8) {
      setError({ ...error, password: 'Password must contain atleast 8 characters' })
      return;
    }
    try {
      if(!verification.shouldVerify) {
        setError({ ...error, captcha: 'Please verify that you are not a robot.' })
        return;
      }
      setLoading(true)
      const { data, status } = await axios.post(`${process.env.REACT_APP_QA_API}/signup`, formData)
      setLoading(false)
      if (status === 200) {
        setMessage('User registered successfully. Please verify your email.')
        setFormData({
          email: '',
          linkedin: '',
          firstname: '',
          lastname: '',
          username: '',
          phone: '',
          job_cities: '',
          referred_by: '',
          key_words: '',
          password: ''
        })
      }
    } catch (error) {
      setLoading(false)
      const statusCode = error.response.status
      if (statusCode === 400) {
        setError({ ...error, signup: 'user with this email already exists.' })
        recaptchaRef.current.reset()
        setVerification({
          isVerified: false,
          shouldVerify: false,
          value: null
        })
      }
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setError({ ...error, [name]: '', signup: '' })
    setFormData({ ...formData, [name]: value })
  }

  const reCaptchaHandler = (code) => {
    setVerification({
      isVerified: !!code,
      shouldVerify: true,
      value: code,
    })
  }
  return (
    <>
      <div className="justify-center flex overflow-x-hidden overflow-y-auto fixed top-0 inset-x-0 z-50 outline-none focus:outline-none h-full p-4">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="my-4 flex justify-center">
            <img src={logo} alt="logo" className="w-64" />
          </div>
          <div className="px-2 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
            {/*header*/}

            <div className="md:w-[32rem] md:mx-8 mx-4 mt-8">
              <div className="mb-8 flex justify-between font-bold text-xl">
                <span className="text-white md:hidden">x</span>
                <span>Sign Up</span>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    setSignUp(false);
                  }}
                >
                  x
                </span>
              </div>
              {error.signup && <div className="text-red-r1">{error.signup}</div>}
              <div className="my-8 md:my-4 flex flex-col md:flex-row justify-between gap-2">
                <div className="md:w-1/2">
                  <div className="text-sm text-semibold required">First name</div>
                  <input
                    className={`w-full px-4 py-2 focus:border-white
                    border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                    ${error.firstname ? 'border-red-r1 focus:border-red-r1' : 
                    'border-gray-g6 focus:border-green-g1'} `}
                    type="text"
                    placeholder="Enter first name"
                    name='firstname'
                    value={formData.firstname}
                    onChange={handleChange}
                  />
                  {error.firstname && <div className="text-red-r1 text-sm">{error.firstname}</div>}
                </div>
                <div className="md:w-1/2">
                  <div className="text-sm text-semibold required">Last name</div>
                  <input
                    className={`w-full px-4 py-2 focus:border-white
                    border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                    ${error.lastname ? 'border-red-r1 focus:border-red-r1' : 
                    'border-gray-g6 focus:border-green-g1'} `}
                    type="text"
                    placeholder="Enter last name"
                    name='lastname'
                    value={formData.lastname}
                    onChange={handleChange}
                  />
                  {error.lastname && <div className="text-red-r1 text-sm">{error.lastname}</div>}
                </div>
              </div>
              <div className="my-8 md:my-4 flex flex-col md:flex-row justify-between gap-2">
                <div className="md:w-1/2">
                  <div className="text-sm text-semibold required">Email</div>
                  <input
                    className={`w-full px-4 py-2 focus:border-white
                    border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                    ${error.email ? 'border-red-r1 focus:border-red-r1' : 
                    'border-gray-g6 focus:border-green-g1'} `}
                    type="email"
                    placeholder="Enter Email"
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {error.email && <div className="text-red-r1 text-sm">{error.email}</div>}
                </div>
                <div className="md:w-1/2">
                  <div className="text-sm text-semibold required">Linkedin</div>
                  <input
                    className={`w-full px-4 py-2 focus:border-white
                    border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                    ${error.linkedin ? 'border-red-r1 focus:border-red-r1' : 
                    'border-gray-g6 focus:border-green-g1'} `}
                    type="text"
                    placeholder="Enter Linkedin profile URL"
                    name='linkedin'
                    value={formData.linkedin}
                    onChange={handleChange}
                  />
                  {error.linkedin && <div className="text-red-r1 text-sm">{error.linkedin}</div>}
                </div>
              </div>
              <div className="my-8 md:my-4 flex flex-col md:flex-row justify-between gap-2">
                <div className="md:w-1/2">
                  <div className="text-sm text-semibold required">Display name for discussion forums</div>
                  <input
                    className={`w-full px-4 py-2 focus:border-white
                    border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                    ${error.username ? 'border-red-r1 focus:border-red-r1' : 
                    'border-gray-g6 focus:border-green-g1'} `}
                    type="text"
                    placeholder="Enter display name"
                    name='username'
                    value={formData.username}
                    onChange={handleChange}
                  />
                  {error.username && <div className="text-red-r1 text-sm">{error.username}</div>}
                </div>
                <div className="md:w-1/2">
                  <div className="text-sm text-semibold">Phone number</div>
                  <input
                    className={`w-full px-4 py-2 focus:border-white
                    border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                    ${error.phone ? 'border-red-r1 focus:border-red-r1' : 
                    'border-gray-g6 focus:border-green-g1'} `}
                    type="text"
                    placeholder="Enter phone number"
                    name='phone'
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="my-8 md:my-4 flex flex-col gap-2">
                <div className="text-sm text-semibold">Desired job cities</div>
                <input
                  className={`w-full px-4 py-2 focus:border-white
                  border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                  ${error.job_cities ? 'border-red-r1 focus:border-red-r1' : 
                  'border-gray-g6 focus:border-green-g1'} `}
                  type="text"
                  placeholder="Enter desired job cities"
                  name='job_cities'
                  value={formData.job_cities}
                  onChange={handleChange}
                />
              </div>
              <div className="my-8 md:my-4 flex flex-col gap-2">
                  <div className="text-sm text-semibold">Keywords for recruiters</div>
                  <input
                    className={`w-full px-4 py-2 focus:border-white
                    border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                    ${error.key_words ? 'border-red-r1 focus:border-red-r1' : 
                    'border-gray-g6 focus:border-green-g1'} `}
                    type="text"
                    placeholder="Add key words"
                    name='key_words'
                    value={formData.key_words}
                    onChange={handleChange}
                  />
              </div>
              <div className="my-8 md:my-4 flex flex-col md:flex-row justify-between gap-2">
                <div className="md:w-1/2">
                  <div className="text-sm text-semibold required">Password</div>
                  <div className="flex">
                    <input
                      className={`w-full px-4 py-2 focus:border-white
                      border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                      ${error.password ? 'border-red-r1 focus:border-red-r1' : 
                      'border-gray-g6 focus:border-green-g1'} `}
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                      name='password'
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <img src={Eye} alt="ShowPassword" className="w-6 -ml-10 cursor-pointer" onClick={() => setShowPassword(!showPassword)} />
                  </div>
                  {error.password && <div className="text-red-r1 text-sm">{error.password}</div>}
                </div>
                <div className="md:w-1/2">
                  <div className="text-sm text-semibold">Referred By</div>
                  <input
                    className={`w-full px-4 py-2 focus:border-white
                    border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                    ${error.referred_by ? 'border-red-r1 focus:border-red-r1' : 
                    'border-gray-g6 focus:border-green-g1'} `}
                    type="text"
                    placeholder="Referred by"
                    name='referred_by'
                    value={formData.referred_by}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {message && (
                <>
                  <div className="text-green-g1">{message}</div>
                  <div className="text-green-g1">{"Please check your spam folder if you didn't get confirmation email."}</div>
                </>
              )}
              {<div className="grid justify-items-center">
                <Reaptcha 
                  sitekey="6LdfTKohAAAAALelrYVqTET45t2w2ON-gHu_XxpU"
                  onVerify={reCaptchaHandler}
                />
              </div>}
              {error.captcha && <div className="text-red-r1 text-sm">{error.captcha}</div>}
              <div className="mx-auto w-32 my-8 py-2 rounded-full text-center text-semibold bg-green-g1 text-white cursor-pointer" onClick={handleSignup}>
                {loading ? 'loading...' : 'Sign Up'}
              </div>
            </div>
            {/* header end */}
          </div>
        </div>
      </div>
      <div className="opacity-95 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

const ForgotModal = ({ setForgotModal, setLoginModal }) => {
  const [formData, setFormData] = useState({
    email: '',
  })
  const [error, setError] = useState({
    email: '',
    forgot: ''
  })
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  const handleSend = async () => {
    if (!formData.email) {
      setError({ ...error, email: 'Email is required' })
      return;
    }
    if (!formData.email.match(emailRegex)) {
      setError({ ...error, email: 'Enter correct email.' })
      return;
    }
    try {
      setLoading(true)
      const { status } = await axios.post(`${process.env.REACT_APP_QA_API}/forgot-password`, formData)
      setLoading(false)
      if (status === 200) {
        setSuccess(true)
      }
    } catch (error) {
      setLoading(false)
      const statusCode = error.response.status
      if (statusCode === 400) {
        setSuccess(false)
        setError({ ...error, forgot: 'User with this email does not exist.' })
      }
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setSuccess(false)
    setError({ ...error, [name]: '', forgot: '' })
    setFormData({ ...formData, [name]: value })
  }
  return (
    <>
      <div className="md:justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-x-0 z-50 outline-none focus:outline-none top-0 h-full">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="my-10 flex justify-center">
            <img src={logo} alt="logo" className="w-64" />
          </div>
          <div className="px-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}

            <div className="md:w-96 mx-4 mt-8">
              <div className="mb-8 flex justify-between font-bold text-xl">
                <span className="flex items-center gap-4">
                  <img
                    src={ArrowLeft}
                    alt="ArrowLeft"
                    className="cursor-pointer"
                    onClick={() => {
                      setLoginModal(true);
                      setForgotModal(false);
                    }}
                  />
                  Type your email
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => setForgotModal(false)}
                >
                  x
                </span>
              </div>

              <div className="my-4 flex flex-col gap-2">
                <div className="text-sm text-semibold">Email</div>
                <input
                  className={`w-full px-4 py-2 focus:border-white
                  border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                  ${error.email ? 'border-red-r1 focus:border-red-r1' : 
                  'border-gray-g6 focus:border-green-g1'} `}
                  type="email"
                  placeholder="Enter Email"
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                />
                {error.email && <div className="text-red-r1 text-sm">{error.email}</div>}
              </div>
              {error.forgot && <div className="text-red-r1 text-sm">{error.forgot}</div>}
              {success && <div className="my-4 text-green-g1">
                We sent recover link. Please check your email!
              </div>}

              <div className="mx-auto w-32 my-8 mt-14 py-2 rounded-full text-center text-semibold bg-green-g1 text-white cursor-pointer" onClick={handleSend}>
                {loading ? "Loading..." : "Send"}
              </div>
            </div>

            {/* header end */}
          </div>
        </div>
      </div>
      <div className="opacity-95 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

const VerifyOTPModal = () => {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('userId')
  const email = localStorage.getItem('email')
  const is_legal_edu = localStorage.getItem("is_legal_edu") === 'true' ? true : false;
  const is_edu = email.includes('.edu') && !email.includes('alumni')
  const [loading, setLoading] = useState(false)

  const [otp, setOtp] = useState('')
  const [error, setError] = useState("")
  const context =React.useContext(MainContext)
  const ref = useRef(null)
  const handleVerifyOTP = async () => {
    if (!otp) {
      setError('OTP code is required' )
      return;
    }

    try {
      setLoading(true)
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token} `
      }
      const ua = `${osName}_${browserName}`
      const device_id = new DeviceUUID().get()
      const { data, status } = await axios.post(`${process.env.REACT_APP_QA_API}/2fa/${userId}`, { otp: otp, device_id: device_id, ua: ua }, { headers })
      setLoading(false)
      if (status === 200) {
        context.setVerifyOTPModal(false)
        if (data.is_login_limited == true) {
          context.setLoginLimitedModal(true)
        } else {
          if (!is_edu) {
            context.setIs2faVerified(true)
            localStorage.setItem('is_2fa_verified', true)
          } else {
            if (is_legal_edu === true) {
              context.setIs2faVerified(true)
              localStorage.setItem('is_2fa_verified', true)
            } else {
              context.setShowLegalDisclaimerModal(true)
              return
            }
          }
          // navigate(context.isVantage === true ? "/linux" : "/statistics")
          navigate(context.is_vantage === true ? "/linux" : ( context.is_certificate ? "/stats" : "/statistics"))
        }
      }
    } catch (error) {
      setLoading(false)
      const statusCode = error.response.status
      if (statusCode === 400) {
        setError(error.response.data.message)
      }
    }
  }
  const sendOTPcode = async() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token} `,
    };
    try {
      if (token) {
        await axios.get(`${process.env.REACT_APP_QA_API}/2fa/${userId}`, { headers })
      }
    } catch (error) {
      console.error(error)
    }
  }
  const handleChange = (e) => {
    setError("")
    setOtp(e.target.value)
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if(event.key === 'Enter') {
        event.preventDefault()
        handleVerifyOTP()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [otp])

  const onCloseModal = () => {
    context.setVerifyOTPModal(false)
    context.login.setIsLoggedIn(false)
    localStorage.clear()
    navigate("/");
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-x-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="my-10 flex justify-center">
            <img src={logo} alt="logo" className="w-64" />
          </div>
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="md:w-96 md:mx-8 mx-4 mt-8">
              <div className="mb-8 flex justify-between font-bold text-xl">
                <span className="text-white md:hidden">x</span>
                <span>Two Factor Authentication</span>
                <span
                  className="cursor-pointer"
                  onClick={() => onCloseModal()}
                >
                  x
                </span>
              </div>

              <div className="md:my-4 my-8 flex flex-col gap-4">
                <div className="text-sm text-semibold">OTP code was sent to your email. Please verify!</div>
                <input
                  className={`w-full px-4 py-2 focus:border-white
                  border border-gray-400 border-solid rounded-full placeholder-text-blue-b1 focus:outline-none 
                  ${error ? 'border-red-r1 focus:border-red-r1' : 
                  'border-gray-g6 focus:border-green-g1'} `}
                  type="email"
                  placeholder="Enter OTP code"
                  name='otp'
                  value={otp}
                  ref={ref}
                  onChange={handleChange}
                />
                {error && <div className="text-red-r1 text-sm">{error}</div>}
              </div>
              <div className="text-center">
                <button className="mx-auto w-32 my-4 md:my-8 mx-4 py-2 rounded-full text-center text-semibold bg-green-g1 text-white cursor-pointer" onClick={sendOTPcode}>
                  {loading ? "loading..." : "Send OTP"}
                </button>
                <button className="mx-auto w-32 my-4 md:my-8 mx-4 py-2 rounded-full text-center text-semibold bg-green-g1 text-white cursor-pointer" onClick={handleVerifyOTP}>
                  {loading ? "loading..." : "Verify"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-95 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export const LoginLimitedModal = () => {
  const context =React.useContext(MainContext)
  const onCloseModal = () => {
    context.setLoginLimitedModal(false)
    context.login.setIsLoggedIn(false)
    localStorage.clear()
  }
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-x-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="my-10 flex justify-center">
            <img src={logo} alt="logo" className="w-64" />
          </div>
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="md:w-96 md:mx-8 mx-4 mt-8">
              <div className="mb-8 flex justify-between font-bold text-xl">
                <span className="text-white md:hidden">x</span>
                <span>You've reached your device limit</span>
                <span
                  className="cursor-pointer"
                  onClick={() => onCloseModal()}
                >
                  x
                </span>
              </div>

              <div className="md:my-4 my-8 flex flex-col gap-4">
                <div className="text-sm text-semibold">
                  You've reached your device limit, only two different devices are permitted to login from per user account.
                </div>
                <div className="text-sm text-semibold">
                  Please contact <span className="text-blue-500"><a href="mailto:support@quantfinanceprep.com">support@quantFinancePrep.com</a></span> if you need to change devices.
                </div>
              </div>
              <div className="text-center">
                <button className="mx-auto w-32 my-4 md:my-8 mx-4 py-2 rounded-full text-center text-semibold bg-green-g1 text-white cursor-pointer" onClick={() => onCloseModal()}>
                  {"Ok"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-95 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export const LegalDisclaimerModal = () => {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('userId')
  const [loading, setLoading] = useState(false)

  const [error, setError] = useState("")
  const context =React.useContext(MainContext)
  const ref = useRef(null)

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token} `,
  };

  const handleSubmit = async() => {
    if (ref.current.checked) {
      try {
        const { data, status } = await axios.put(
          `${process.env.REACT_APP_QA_API}/user-profile/${userId}`,
          {is_legal_edu: true},
          { headers }
        );
        if (status === 200) {
          context.setShowLegalDisclaimerModal(false)
          context.setIs2faVerified(true)
          localStorage.setItem('is_2fa_verified', true)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setError('Please check in if you agree.')
    }
  }

  const onClose = async() => {
    context.setShowLegalDisclaimerModal(false)
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token} `
    }
    await axios.post(`${process.env.REACT_APP_QA_API}/logout`, {}, {headers})
    localStorage.clear()
    context.login.setIsLoggedIn(false)
    navigate("/");
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-x-0 z-50 outline-none focus:outline-none top-0 h-full">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="my-10 flex justify-center">
            <img src={logo} alt="logo" className="w-64" />
          </div>
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="md:w-96 md:mx-8 mx-4 mt-8">
              <div className="mb-8 flex justify-between font-bold text-xl">
                <span className="text-white md:hidden">x</span>
                <span>Legal disclaimer</span>
                <span
                  className="cursor-pointer"
                  onClick={onClose}
                >
                  x
                </span>
              </div>

              <div className="md:my-4 my-8 flex flex-col gap-4">
                <label className="text-sm text-semibold text-justify">
                  <input
                    className="mx-2"
                    type="checkbox"
                    name='is_legal_edu_checkbox'
                    value={false}
                    ref={ref}
                  />
                  I hereby declare that I am a full time student enrolled in a university currently, 
                  and that any misrepresentation is subject to penalty for perjury.
                </label>
                {error && <div className="text-red-r1 text-sm">{error}</div>}
              </div>
              <div className="text-center">
                <button className="mx-auto w-32 my-4 md:my-4 mx-2 py-2 rounded-full text-center text-semibold bg-green-g1 text-white cursor-pointer" onClick={handleSubmit}>
                  {loading ? "loading..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-95 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export const SupportModal = ({setShowSupportModal}) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-x-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="my-10 flex justify-center">
            <img src={logo} alt="logo" className="w-64" />
          </div>
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="md:w-96 md:mx-8 mx-4 mt-8">
              <div className="mb-8 flex justify-between font-bold text-xl">
                <span className="text-white md:hidden">x</span>
                <span>Customer Support</span>
                <span
                  className="cursor-pointer"
                  onClick={() => setShowSupportModal(false)}
                >
                  x
                </span>
              </div>

              <div className="md:my-4 my-8 flex flex-col gap-4">
                <div className="text-sm text-semibold">
                  Contact us at <span className="text-blue-500"><a href="mailto:support@quantfinanceprep.com">support@quantFinancePrep.com</a></span>
                </div>
              </div>
              <div className="text-center">
                <button className="mx-auto w-32 my-4 md:my-8 mx-4 py-2 rounded-full text-center text-semibold bg-green-g1 text-white cursor-pointer" onClick={() => setShowSupportModal(false)}>
                  {"Ok"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-95 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

function TabList({ setSelectedTab, selectedTab, open, setOpen }) {
  const context =React.useContext(MainContext)

  const onChangeTab = (id) => {
    setSelectedTab(id)
    context.setActiveUri('')
    try {
      setOpen(!open)
    } catch {
    }
  }
  return (
    <div className="flex flex-col justify-between">
      <div>
        {TAB_LIST_ARRAY.slice(0, 5).map(({ id, title, href }) => (
          <Link to={href} key={title}>
            <div
              className={`text-center py-2 rounded-full cursor-pointer ${selectedTab === id && " bg-gray-g8"
                }`}
              key={id}
              onClick={() => onChangeTab(id)}
            >
              {title}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
