import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Comments from "./images/Comments.svg";

import axios from 'axios';
import { MainContext } from "../../../App";


function Items({currentItems, searchQuery, setActiveUri, setCount, setInterviewId}) {
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem('token')
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token ${token} `
  }
  const updateAccessHistory = async (interview) => {
    var interview_ = interview
    if (!interview_.access_history.includes(parseInt(userId))) {
      interview_.access_history = [...interview_.access_history, parseInt(userId)]
      try {
        await axios.put(
          `${process.env.REACT_APP_QA_API}/interview_/${interview.id}`,
          interview_,
          { headers }
        );
      } catch (error) {
        
      }
    }
  }
  const handleInterviewOpen = (interview, discussions) => {
    updateAccessHistory(interview)
    setActiveUri(interview.content_uri)
    setCount(discussions.length)
    setInterviewId(interview.id)
  }
  return (
    <div className="py-6 text-gray-g1 flex flex-col gap-1">
    {currentItems && currentItems
      .filter((ele) => ele.interview.title.toLowerCase().includes(searchQuery.toLowerCase()))
      .map(({ interview, discussions }, index) => (
        <div
          className="py-2 px-3 rounded text-gray-g5 flex justify-between font-bold cursor-pointer hover:shadow"
          key={index}
          onClick={() => handleInterviewOpen(interview, discussions)}
        >
          {interview.access_history.includes(parseInt(userId)) ? (
            <span className="text-blue-600 font-verdana text-xl font-normal">
              {interview.title.charAt(0).toUpperCase()}
              {interview.title.slice(1)}
            </span>
          ) : (
            <span className="text-blue-600 font-verdana text-xl font-normal">
              {interview.title.charAt(0).toUpperCase()}
              {interview.title.slice(1)}
            </span>
          )}
          
          <Link to={`/discussions?interview=${interview.id}`} key={`theme-data-${index}`}>
            <span className="flex gap-2">
              <img src={Comments} alt="Comments" className="w-4" />
              {discussions.length}
            </span>
          </Link>
        </div>
      ))}
    </div>
  )
}

function ThemeItems({ themeData, searchQuery, setActiveUri, setCount, setInterviewId }) {
  return (
    <>
      <Items currentItems={themeData} searchQuery={searchQuery} setActiveUri={setActiveUri} setCount={setCount} setInterviewId={setInterviewId} />
    </>
  );
}

function capitalizeFirstLetter(str) {
  str = str.toLowerCase()
  const capitalized = str.replace(/^./, str[0].toUpperCase());
  return capitalized;
}

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window
  return {
    width,
    height
  }
}

const Statistics = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const interviewType = pathname.split('/')[1].toUpperCase()
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const [prevIndex, setPrevIndex]= useState(-1)
  const [nextIndex, setNextIndex] = useState(-1)
  const [prevIndexTopicTitle, setPrevIndexTopicTitle] = useState('')
  const [nextIndexTopicTitle, setNextIndexTopicTitle] = useState('')
  // adding company filter
  const [companies, setCompanies] = useState([])
  const [topicId, setTopicId] = useState('')
  const [filterVaule, setFilterValue] = useState('')
  const location = useLocation();
  // adding company filter
  const context =React.useContext(MainContext)
  // adding company filter
  const [count, setCount] = useState(0)
   // Reset filterValue when the tab (route) changes
  useEffect(() => {
    setFilterValue(""); // Reset filter value
  }, [location.pathname]);
  const loadCompanies = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token}`
    };
    console.log("companies ApI called")
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_QA_API}/interview_/company/`, { headers });
      setCompanies(data.length ? data : []);
    } catch (error) {
      console.error("Failed to load companies:", error);
      setCompanies([]);
    }
  };
  // adding company filter
  const apiCall = async (filterVaule_ = '') => {
    const token = localStorage.getItem('token')
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token} `
    }
    try {
      setLoading(true)
      if (token) {
        const { data } = await axios.get(`${process.env.REACT_APP_QA_API}/interview_/${interviewType}?filter=${filterVaule_}`, { headers })
        setLoading(false)
        context.setInterviewThemeData(data)
      } else {
        navigate('/')
      }
    } catch (error) {
      setLoading(false)
      
      if (error.response.status === 401) {
        context.setIsLoggedIn(false)
        localStorage.clear()
        navigate('/')
      }
      setError('Some error occured. Please reload your page.')
    }
  }
  useEffect(() => {
    apiCall()
  }, [pathname])
  
  const params = new URLSearchParams(window.location.search)
  const interviewId_ = params.get("interview")

  useEffect(() => {
    if (context.activeUri === "" || context.activeUri === false) {
      // apiCall()

      let count_ = 0
      context.interviewThemeData.forEach((el) => { count_ += el.discussions.length })
      context.setInterviewCount(count_)

      if (interviewId_ !== null) {
        const interview_ = context.interviewThemeData.filter((ele) => ele.interview.id === Number(interviewId_))
        if (interview_.length > 0) {
          context.setActiveUri(interview_[0].interview.content_uri)
          context.setInterviewCount(interview_[0].discussions.length)
        }
      }
    }
    if (context.activeUri !== "") {
      const currentIndex = context.interviewThemeData.findIndex(item => item.interview.id === context.interviewId);
      setPrevIndex(currentIndex - 1)
      setNextIndex((currentIndex + 1) < context.interviewThemeData.length ? (currentIndex + 1) : -1)
      setPrevIndexTopicTitle(currentIndex - 1 > -1 ? context.interviewThemeData[currentIndex - 1].interview.title : '')
      setNextIndexTopicTitle((currentIndex + 1) < context.interviewThemeData.length ? context.interviewThemeData[currentIndex + 1].interview.title : '')
    }
  }, [context.activeUri, interviewId_])
  //  adding company filter
  useEffect(() => {
      loadCompanies()
      function handleResize() {
        setWindowDimensions(getWindowDimensions())
      }
      window.addEventListener('resize', handleResize)
      return () =>  window.removeEventListener('resize', handleResize)
    }, [])
  // adding company filter
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () =>  window.removeEventListener('resize', handleResize)
  }, [])

  const handlePrevNext = (_pos) => {
    context.setActiveUri(context.interviewThemeData[_pos].interview.content_uri);
    context.setInterviewCount(context.interviewThemeData[_pos].discussions.length);
    context.setInterviewId(context.interviewThemeData[_pos].interview.id);
  }
  // adding company filter
  const handleFilterChange = (event) =>  {
    setFilterValue(event.target.value)
    apiCall(event.target.value)
  }
  // adding company filter
  return (
    <div className="flex flex-row overflow-hidden table-container w-full">
      <div className="w-full bg-gray-g1 flex flex-col md:justify-between p-3 md:p-6">
        {loading ? <div className="h-screen text-white">Loading...</div> : <>
          <div className="flex justify-between">
            <div></div>
            {context.interviewId && <Link to={`/discussions?interview=${interviewId_ === null ? context.interviewId : interviewId_}`}>
              <div
                className={`mx-2 my-2 z-3 md:flex justify-end items-center font-bold cursor-pointer hidden md:right-4 z-1`}
              >
                <div className="mx-2 flex justify-end items-center font-bold cursor-pointer">
                  <div className="py-2 px-4 rounded-full shadow-lg text-gray-200 bg-gray-g8">
                    <span>Discussion</span>
                    <span className="ml-4 px-4 py-1 text-white bg-green-g1 rounded-full">{context.interviewCount}</span>
                  </div>
                </div>
              </div>
              <div
                className={`mx-2 z-3 flex justify-center left-0 right-0 items-center font-bold cursor-pointer md:hidden`}
              >
                <div className="mx-2 flex justify-end items-center font-bold cursor-pointer py-2">
                  <div className="py-2 px-4 rounded-full shadow-lg text-gray-200 bg-gray-g8">
                    <span>Discussion</span>
                    <span className="ml-4 px-4 py-1 text-white bg-green-g1 rounded-full">{context.interviewCount}</span>
                  </div>
                </div>
              </div>
            </Link>}
          </div>
          {/*  adding company filter */}
            <div className="flex justify-between">
              {/* {context.activeUri ? (<div></div>) : ( */}
                <div className="m-2 flex">
                  <div className="px-6 py-2 bg-green-g1 rounded-full font-semibold text-white">Filter by company</div>
                  <select className="filter-select mx-2 px-4 py-1 pr-8 rounded-full outline-none cursor-pointer border border-green-500 focus:ring-green-500 focus:border-green-500 focus-visible:border-green-500" value={filterVaule} onChange={handleFilterChange}>
                    <option value="">-</option>
                    {companies?.map(({name, value}, index) => (
                      <option value={`${value}`}  key={`select-option-${index}`}>{name}</option>
                    ))}
                  </select>
                </div>
              {/* )} */}
              
              {topicId && <Link to={`/discussions?topic=${interviewId_ === null ? topicId : interviewId_}`}>
                <div
                  className={`mx-2 my-2 z-3 md:flex justify-end items-center font-bold cursor-pointer hidden md:right-4 z-1`}
                >
                  <div className="mx-2 flex justify-end items-center font-bold cursor-pointer">
                    <div className="py-2 px-4 rounded-full shadow-lg bg-gray-200">
                      <span>Discussion</span>
                      <span className="ml-4 px-4 py-1 text-white bg-green-g1 rounded-full">{count}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`mx-2 z-3 flex justify-center left-0 right-0 items-center font-bold cursor-pointer md:hidden`}
                >
                  <div className="mx-2 flex justify-end items-center font-bold cursor-pointer py-2">
                    <div className="py-2 px-4 rounded-full shadow-lg bg-gray-200">
                      <span>Discussion</span>
                      <span className="ml-4 px-4 py-1 text-white bg-green-g1 rounded-full">{count}</span>
                    </div>
                  </div>
                </div>
              </Link>}
            </div>
            {/*  adding company filter */}
          {context.activeUri ? 
            <>
              <iframe src={context.activeUri} title={context.activeUri} width='100%' className="h-[75vh] mt-3" />
              <div className="flex justify-between bg-gray-g1 text-white py-2">
                {prevIndex === -1 ? (<div></div>) : (
                  <div className="flex cursor-pointer" onClick={() => handlePrevNext(prevIndex)}>
                    <div className="w-5 h-5 m-5">
                      <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 11v2H8l5.5 5.5-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5 8 11h12Z"></path></svg>
                    </div>
                    <div className="text-left">
                      <span className="text-xs">Previous</span>
                      <div className="text-base">
                        {prevIndexTopicTitle.charAt(0).toUpperCase()}
                        {prevIndexTopicTitle.slice(1)}
                      </div>
                    </div>
                  </div>
                )}
                {nextIndex === -1 ? (<div></div>) : (
                  <div className="flex cursor-pointer" onClick={() => handlePrevNext(nextIndex)}>
                    <div className="text-right">
                      <span className="text-xs">Next</span>
                      <div className="text-base">
                        {nextIndexTopicTitle.charAt(0).toUpperCase()}
                        {nextIndexTopicTitle.slice(1)}
                      </div>
                    </div>
                    <div className="w-5 h-5 m-5">
                      <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 11v2h12l-5.5 5.5 1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5 16 11H4Z"></path></svg>
                    </div>
                  </div>
                )}
              </div>
              <div className="text-xs py-4 text-center bg-gray-g8 text-white">
                {"Copyright © 2025 QuantFinancePrep"}
              </div>
            </>
             : 
            <div className="h-[85vh] overflow-auto">
              {context.interviewThemeData && context.interviewThemeData.filter((ele) => ele.interview.title.toLowerCase().includes(searchQuery.toLowerCase())).length === 0 ? 
                <div className="mt-4 text-white">No result found.</div> : ""}
            </div>
          }
        </>}
        {error && <div>Some error occured. Please try again.</div>}
      </div>
    </div>
  );
};

export default Statistics;