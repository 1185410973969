import React from "react";
import { Header, Footer } from "../../shared";
import { MainContext } from "../../../App";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Vantage = () => {
  const [forgotModal, setForgotModal] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const [showSupportModal, setShowSupportModal] = React.useState(false);
  const [canvaUri, setCanvaUri] = React.useState(null);
  const context = React.useContext(MainContext);
  const is_2fa = localStorage.getItem("is_2fa") === "true" ? true : false;

  const navigate = useNavigate();
  const currentURL = window.location.pathname;

  useEffect(() => {
    if (is_2fa && !context.is2faVerified && context.login.isLoggedIn) {
      if (context.isNewDevice === false) {
        context.setIs2faVerified(true);
        localStorage.setItem("is_2fa_verified", true);
        // navigate(context.isVantage === true ? "/linux" : "/statistics");
        navigate(context.isVantage === true ? "/linux" : ( context.isCertificate ? "/stats" : "/statistics"))
      } else {
        if (context.isLoginLimited === true) {
          context.setLoginLimitedModal(true);
          localStorage.clear();
          context.login.setIsLoggedIn(false);
        } else {
          context.setVerifyOTPModal(true);
        }
      }
    }
  }, [currentURL, context.login.isLoggedIn]);

  const loadCanvaUri = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_QA_API}/vantage_/canva_uri/`
    );
    setCanvaUri(data.canva_uri);
  };

  useEffect(() => {
    loadCanvaUri();
  }, []);

  return (
    <>
      <div className="">
        <Header
          className="relative"
          login={context.login}
          loginModal={context.loginModal}
          setLoginModal={context.setLoginModal}
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          signUp={signUp}
          setSignUp={setSignUp}
          verifyOTPModal={context.verifyOTPModal}
          setVerifyOTPModal={context.setVerifyOTPModal}
          LoginLimitedModal={context.isLoginLimited}
          setLoginLimitedModal={context.setLoginLimitedModal}
          showTermsModal={showTermsModal}
          setShowTermsModal={setShowTermsModal}
          showSupportModal={showSupportModal}
          setShowSupportModal={setShowSupportModal}
        />
      </div>
      <div className="flex flex-col article-section px-8">
        <div className="py-12 text-gray-g1 flex flex-col gap-1"></div>
        <div className="text-2xl font-bold">
          <a href="/vantage">Vantage</a>
        </div>
        <div className="text-gray-g1 flex flex-col gap-1 w-full md:w-[80%] m-auto">
          <h1 className="text-center text-4xl font-bold mb-4 text-green-g2">
            Introducing Vantage: Your Gateway to Career Excellence
          </h1>
          <p className="mb-2">
            More than just a platform, Vantage is your partner in navigating the complexities 
            of the modern workplace.
          </p>
          <p className="mb-2">
            Tailored for new hires, mid-level employees, and interns alike, Vantage is a <b>work performance enhancement 
            tool</b> that <b>empowers</b> quant finance professionals and data 
            scientists to <b>excel</b> and <b>achieve career success</b> from day one.
          </p>
          <h2 className="text-xl font-bold my-2 text-green-g2">Why Vantage?</h2>
          <p className="mb-2">
            In today's competitive landscape, internships and the initial years in a 
            job serve as prolonged interviews. Making a lasting impression on your managers and peers during 
            this crucial period is paramount. Vantage ensures you stand out by equipping you with essential 
            skills and knowledge for rapid career advancement.
          </p>
          <h2 className="text-xl font-bold my-2 text-green-g2">What Does Vantage Offer?</h2>
          <p className="mb-2">
            Vantage encompasses comprehensive sub-modules 
            covering <b>Linux, Python, Data Science, Visualization, & Finance Applications</b>, each delving 
            deeply into the essential themes necessary for success in quantitative fields.
          </p>
          <p className="mb-2">With complete Python code, interactive platforms for coding practice, and dedicated 
            discussion forums, Vantage provides unparalleled support for upskilling.
          </p>
          <p className="mb-2 font-bold text-green-g2">Explore a Wealth of Topics:</p>
          <ol className="list-disc ml-8">
            <li className="mb-2">
              Dig into the world of <b>Linux</b> and learn <b>shell programming</b>
            </li>
            <li className="mb-2">
              Master the intricacies of <b>Python</b> programming and <b>Code organization</b>
            </li>
            <li className="mb-2">
              Navigate advanced applications of libraries like <b>NumPy</b>
            </li>
            <li className="mb-2">
              Harness the power of creating <b>interactive dashboards</b> using <b>ipywidgets</b>, <b>Matplotlib</b>, or <b>Plotly</b>
            </li>
            <li className="mb-2">
              Transform <b>ideas into trading strategies</b> employing Financial Applications 
            </li>
          </ol>
          <h2 className="text-xl font-bold my-2 text-green-g2">Continuous Improvement, Continuous Learning:</h2>
          <p className="mb-2">
            With Vantage, your learning never stagnates. Benefit from continuous 
            updates and new themes added regularly to ensure you stay ahead of the curve.
          </p>
          <h2 className="text-xl font-bold my-2 text-green-g2">Unlock Your Potential Today:</h2>
          <p className="mb-2">For only $599 per 3 months or $999 per year, subscribers gain access to:</p>
          <ol className="list-disc ml-8">
            <li className="mb-2">
              A sophisticated learning platform containing a comprehensive collection of 
              the best industry practices across multiple disciplines 
              including: <span className="italic">Python, Intricacies of Data Science, Advanced Visualizations, Unix Programming, & Financial Applications.</span>
            </li>
            <li className="mb-2">
              Dedicated discussion forums for clarifying technical doubts and obtaining 
              expert support and guidance.
            </li>
            <li className="mb-2">
              Continuous updates and platform enhancements throughout the year.
            </li>
          </ol>
          <p className="text-xl font-bold text-center my-4 text-green-g2">
            Don't just advance your career, propel it forward with Vantage!
          </p>
        </div>
        <div className="text-4xl font-bold text-center mt-4">Pricing</div>
        <div className="mx-4 my-4 flex gap-6 md:gap-4 flex-col md:flex-row justify-between">
          <div className="w-11/12 md:w-1/2 flex flex-col items-center text-center">
            <div className="my-6">
              <div className="text-green-g1 text-5xl font-bold">$599</div>
              <div className="text-sm md:text-lg">for 3 months</div>
            </div>
          </div>
          <div className="w-11/12 md:w-1/2 flex flex-col items-center text-center">
            <div className="my-6">
              <div className="text-green-g1 text-5xl font-bold">$999</div>
              <div className="text-sm md:text-lg">for year</div>
            </div>
          </div>
        </div>
        <div className="text-gray-g1 flex flex-col gap-1 w-full md:w-[80%] m-auto">
          {canvaUri && (
            <div className="relative w-full h-0 pb-[56.2225%] shadow-md mt-8 mb-8 overflow-hidden rounded-md">
              <iframe
                className="absolute w-full h-full top-0 left-0 border-none p-0 m-0"
                src={canvaUri}
                allowFullScreen
                allow="fullscreen"
                loading="lazy"
                title="At a Glance"
              ></iframe>
            </div>
          )}
        </div>
      </div>
      <Footer
        setShowTermsModal={setShowTermsModal}
        setShowSupportModal={setShowSupportModal}
      />
    </>
  );
};

export default Vantage;
