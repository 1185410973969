import React from "react";

import { InterviewTopBar, InterviewLeftSideBar } from "../interview";
// import SideBar from "./header/SideBar";
import 'react-toastify/dist/ReactToastify.css'
import { MainContext } from "../../App";


const Interview = ({children}) => {
  const [open, setOpen] = React.useState(false)
  const context =React.useContext(MainContext)
  
  return (
    <>
      <div className="md:flex flex-col">
        <InterviewTopBar open={open} setOpen={setOpen} />
        <div className="flex flex-row table-container">
          <InterviewLeftSideBar themeData={context.interviewThemeData} setActiveUri={context.setActiveUri} setCount={context.setInterviewCount} setInterviewId={context.setInterviewId} interviewId={context.interviewId} />
          {!open && <>{children}</>}
        </div>
      </div>
    </>
  );
};

export default Interview;
