import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Comments from "./images/Comments.svg";

import axios from 'axios';
import { MainContext } from "../../../App";


function Items({currentItems, searchQuery, setActiveUri, setCount, setCertificateId}) {
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem('token')
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token ${token} `
  }
  const updateAccessHistory = async (certificate) => {
    var certificate_ = certificate
    if (!certificate_.access_history.includes(parseInt(userId))) {
      certificate_.access_history = [...certificate_.access_history, parseInt(userId)]
      try {
        await axios.put(
          `${process.env.REACT_APP_QA_API}/certificate_/${certificate.id}`,
          certificate_,
          { headers }
        );
      } catch (error) {
        console.log(error)
      }
    }
  }
  const handleCertificateOpen = (certificate, discussions) => {
    updateAccessHistory(certificate)
    setActiveUri(certificate.content_uri)
    setCount(discussions.length)
    setCertificateId(certificate.id)
  }
  return (
    <div className="py-6 text-gray-g1 flex flex-col gap-1">
    {currentItems && currentItems
      .filter((ele) => ele.certificate.title.toLowerCase().includes(searchQuery.toLowerCase()))
      .map(({ certificate, discussions }, index) => (
        <div
          className="py-2 px-3 rounded text-gray-g5 flex justify-between font-bold cursor-pointer hover:shadow"
          key={index}
          onClick={() => handleCertificateOpen(certificate, discussions)}
        >
          {certificate.access_history.includes(parseInt(userId)) ? (
            <span className="text-blue-600 font-verdana text-xl font-normal">
              {certificate.title.charAt(0).toUpperCase()}
              {certificate.title.slice(1)}
            </span>
          ) : (
            <span className="text-blue-600 font-verdana text-xl font-normal">
              {certificate.title.charAt(0).toUpperCase()}
              {certificate.title.slice(1)}
            </span>
          )}
          
          <Link to={`/discussions?certificate=${certificate.id}`} key={`theme-data-${index}`}>
            <span className="flex gap-2">
              <img src={Comments} alt="Comments" className="w-4" />
              {discussions.length}
            </span>
          </Link>
        </div>
      ))}
    </div>
  )
}

function ThemeItems({ themeData, searchQuery, setActiveUri, setCount, setCertificateId }) {
  return (
    <>
      <Items currentItems={themeData} searchQuery={searchQuery} setActiveUri={setActiveUri} setCount={setCount} setCertificateId={setCertificateId} />
    </>
  );
}

function capitalizeFirstLetter(str) {
  str = str.toLowerCase()
  const capitalized = str.replace(/^./, str[0].toUpperCase());
  return capitalized;
}

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window
  return {
    width,
    height
  }
}

const Statistics = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const certificateType = pathname.split('/')[1].toUpperCase()
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const [prevIndex, setPrevIndex]= useState(-1)
  const [nextIndex, setNextIndex] = useState(-1)
  const [prevIndexTopicTitle, setPrevIndexTopicTitle] = useState('')
  const [nextIndexTopicTitle, setNextIndexTopicTitle] = useState('')

  const context =React.useContext(MainContext)


  const apiCall = async (filterVaule_ = '') => {
    const token = localStorage.getItem('token')
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token} `
    }
    try {
      setLoading(true)
      if (token) {
        const { data } = await axios.get(`${process.env.REACT_APP_QA_API}/certificate_/${certificateType}?filter=${filterVaule_}`, { headers })
        setLoading(false)
        context.setCertificateThemeData(data)
      } else {
        navigate('/')
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
      if (error.response.status === 401) {
        context.setIsLoggedIn(false)
        localStorage.clear()
        navigate('/')
      }
      setError('Some error occured. Please reload your page.')
    }
  }
  useEffect(() => {
    apiCall()
  }, [pathname])
  
  const params = new URLSearchParams(window.location.search)
  const certificateId_ = params.get("certificate")

  useEffect(() => {
    if (context.activeUri === "" || context.activeUri === false) {
      apiCall()

      let count_ = 0
      context.certificateThemeData.forEach((el) => { count_ += el.discussions.length })
      context.setCertificateCount(count_)

      if (certificateId_ !== null) {
        const certificate_ = context.certificateThemeData.filter((ele) => ele.certificate.id === Number(certificateId_))
        if (certificate_.length > 0) {
          context.setActiveUri(certificate_[0].certificate.content_uri)
          context.setCertificateCount(certificate_[0].discussions.length)
        }
      }
    }
    if (context.activeUri !== "") {
      const currentIndex = context.certificateThemeData.findIndex(item => item.certificate.id === context.certificateId);
      setPrevIndex(currentIndex - 1)
      setNextIndex((currentIndex + 1) < context.certificateThemeData.length ? (currentIndex + 1) : -1)
      setPrevIndexTopicTitle(currentIndex - 1 > -1 ? context.certificateThemeData[currentIndex - 1].certificate.title : '')
      setNextIndexTopicTitle((currentIndex + 1) < context.certificateThemeData.length ? context.certificateThemeData[currentIndex + 1].certificate.title : '')
    }
  }, [context.activeUri, certificateId_])

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () =>  window.removeEventListener('resize', handleResize)
  }, [])

  const handlePrevNext = (_pos) => {
    context.setActiveUri(context.certificateThemeData[_pos].certificate.content_uri);
    context.setCertificateCount(context.certificateThemeData[_pos].discussions.length);
    context.setCertificateId(context.certificateThemeData[_pos].certificate.id);
  }

  return (
    <div className="flex flex-row overflow-hidden table-container w-full">
      <div className="w-full bg-gray-g1 flex flex-col md:justify-between p-3 md:p-6">
        {loading ? <div className="h-screen text-white">Loading...</div> : <>
          <div className="flex justify-between">
            <div></div>
            {context.certificateId && <Link to={`/discussions?certificate=${certificateId_ === null ? context.certificateId : certificateId_}`}>
              <div
                className={`mx-2 my-2 z-3 md:flex justify-end items-center font-bold cursor-pointer hidden md:right-4 z-1`}
              >
                <div className="mx-2 flex justify-end items-center font-bold cursor-pointer">
                  <div className="py-2 px-4 rounded-full shadow-lg text-gray-200 bg-gray-g8">
                    <span>Discussion</span>
                    <span className="ml-4 px-4 py-1 text-white bg-green-g1 rounded-full">{context.certificateCount}</span>
                  </div>
                </div>
              </div>
              <div
                className={`mx-2 z-3 flex justify-center left-0 right-0 items-center font-bold cursor-pointer md:hidden`}
              >
                <div className="mx-2 flex justify-end items-center font-bold cursor-pointer py-2">
                  <div className="py-2 px-4 rounded-full shadow-lg text-gray-200 bg-gray-g8">
                    <span>Discussion</span>
                    <span className="ml-4 px-4 py-1 text-white bg-green-g1 rounded-full">{context.certificateCount}</span>
                  </div>
                </div>
              </div>
            </Link>}
          </div>
          {context.activeUri ? 
            <>
              <iframe src={context.activeUri} title={context.activeUri} width='100%' className="h-[75vh] mt-3" />
              <div className="flex justify-between bg-gray-g1 text-white py-2">
                {prevIndex === -1 ? (<div></div>) : (
                  <div className="flex cursor-pointer" onClick={() => handlePrevNext(prevIndex)}>
                    <div className="w-5 h-5 m-5">
                      <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 11v2H8l5.5 5.5-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5 8 11h12Z"></path></svg>
                    </div>
                    <div className="text-left">
                      <span className="text-xs">Previous</span>
                      <div className="text-base">
                        {prevIndexTopicTitle.charAt(0).toUpperCase()}
                        {prevIndexTopicTitle.slice(1)}
                      </div>
                    </div>
                  </div>
                )}
                {nextIndex === -1 ? (<div></div>) : (
                  <div className="flex cursor-pointer" onClick={() => handlePrevNext(nextIndex)}>
                    <div className="text-right">
                      <span className="text-xs">Next</span>
                      <div className="text-base">
                        {nextIndexTopicTitle.charAt(0).toUpperCase()}
                        {nextIndexTopicTitle.slice(1)}
                      </div>
                    </div>
                    <div className="w-5 h-5 m-5">
                      <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 11v2h12l-5.5 5.5 1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5 16 11H4Z"></path></svg>
                    </div>
                  </div>
                )}
              </div>
              <div className="text-xs py-4 text-center bg-gray-g8 text-white">
                {"Copyright © 2025 QuantFinancePrep"}
              </div>
            </>
             : 
            <div className="h-[85vh] overflow-auto">
              {context.certificateThemeData && context.certificateThemeData.filter((ele) => ele.certificate.title.toLowerCase().includes(searchQuery.toLowerCase())).length === 0 ? 
                <div className="mt-4 text-white">No result found.</div> : ""}
            </div>
          }
        </>}
        {error && <div>Some error occured. Please try again.</div>}
      </div>
    </div>
  );
};

export default Statistics;